import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, PRIMARY_OUTLET, Router, UrlSegment } from '@angular/router';
import { EventName } from '@app/core/models/event-bus.models';
import { SnackBarType } from '@app/core/models/snack-bar-data.model';
import { BaseStateService } from '@app/core/services/base.state.service';
import { EventBusService } from '@app/core/services/event-bus.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { SnackbarService } from '@app/core/services/snack-bar.service';
import { WsService } from '@app/core/services/ws.service';
import { NotificationKeyBackend } from '@app/preferences/models/settings.models';
import { NotificationService } from '@app/preferences/services/notification.service';
import { environment } from '@environment/environment';
import { saveAs } from 'file-saver-es';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  firstValueFrom,
  forkJoin,
  map,
  Observable,
  of,
  tap,
} from 'rxjs';

import {
  AllLocalFaxNumber,
  FaxLinks,
  FaxMessage,
  FaxMessageResponse,
  FaxUrlResponse,
  ListFaxNumbersResponse,
  LocalFaxNumber,
} from '../models/fax.models';

export type DataLoadState = 'initial' | 'loading' | 'loaded';
export const DEFAULT_FAX_CALLER_ID = '13214246840';

@Injectable({
  providedIn: 'root',
})
export class FaxService extends BaseStateService<FaxMessage> {
  // USE WRONG ONE WHICH WILL BE REPLACED LATER
  path = '';
  protected override baseUrl = `${environment.faxHubGateway}/{me}/faxes`;

  public sendingSubject = new BehaviorSubject<boolean>(false);
  public sending$ = this.sendingSubject.asObservable();

  public readonly dataLoadStateSubject = new BehaviorSubject<DataLoadState>('initial');
  public readonly dataLoadState$ = this.dataLoadStateSubject.asObservable();

  public readonly localFaxNumbersSubject = new BehaviorSubject<LocalFaxNumber[] | null>(null);
  public readonly localFaxNumbers$ = this.localFaxNumbersSubject.asObservable();
  public readonly hasLocalFaxNumbersSubject = new BehaviorSubject<boolean>(false);
  public readonly hasLocalFaxNumbers$ = this.hasLocalFaxNumbersSubject.asObservable();

  /**
   * Every time our URL changes (i.e. when we receive a `NavigationEnd` event),
   * update the selectedLocalNumber based on whatever is in the url. If the url contains
   * a number that doesn't belong to the user, set the value to undefined.
   */
  public readonly selectedFaxLocalNumberSubject = new BehaviorSubject<LocalFaxNumber | null>(null);
  public readonly selectedFaxLocalNumber$ = this.selectedFaxLocalNumberSubject.asObservable();

  public faxBlobPdfData: Map<string, Blob> = new Map();
  // Maps message id to message object.
  private faxMessageMap: Map<string, FaxMessage> = new Map();

  private currentUrl: string;
  private faxLinks: Map<FaxMessage['direction'], FaxLinks> = new Map();

  // ========== Getters / Setters ==========

  public get allLocalNumber(): LocalFaxNumber {
    return AllLocalFaxNumber;
  }

  // ========== Lifecycle ===========

  constructor(
    httpClient: HttpClient,
    private wsService: WsService,
    eventBusService: EventBusService,
    private router: Router,
    private snackbar: SnackbarService,
    private notificationService: NotificationService,
    private storage: LocalStorageService
  ) {
    super(httpClient);

    eventBusService.on(EventName.Logout, () => {
      this.dataLoadStateSubject.next('initial');
      this.localFaxNumbersSubject.next(null);
      this.selectedFaxLocalNumberSubject.next(null);
    });

    const selectedFaxLocalNumberStorageKey = 'selectedFaxLocalNumberStorageKey';
    const lastStoredLocalNumber = this.storage.get<LocalFaxNumber>(selectedFaxLocalNumberStorageKey);
    if (lastStoredLocalNumber) {
      this.selectedFaxLocalNumberSubject.next(lastStoredLocalNumber);
    }

    // Always persist the selectedLocalNumber to localStorage
    this.selectedFaxLocalNumber$.subscribe((value) => {
      if (value === null) {
        this.storage.delete(selectedFaxLocalNumberStorageKey);
      } else {
        this.storage.set(selectedFaxLocalNumberStorageKey, value);
      }
    });

    this.localFaxNumbers$.subscribe((localNumbers) => {
      const hasLocalNumbers = (localNumbers && localNumbers.length > 0) || false;
      this.hasLocalFaxNumbersSubject.next(hasLocalNumbers);
    });

    // Observe outbound messages
    this.wsService.socket.on('FaxSent', (message: FaxMessage) => {
      this.processFaxMessage(message, 'outbound');
    });

    // Observe inbound messages
    this.wsService.socket.on('FaxReceived', (message: FaxMessage) => {
      this.processFaxMessage(message, 'inbound');
      this.snackbar.open('New Fax Received');
      this.notificationService.showNotification(
        { title: 'New fax received', body: message.contact?.fullName ? `from ${message.contact?.fullName}` : '' },
        NotificationKeyBackend['Incoming Fax']
      );
    });

    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => event as NavigationEnd)
      )
      .subscribe((navigationEndEvent) => {
        this.currentUrl = navigationEndEvent.url;
        this.setLocalNumberFromUrl();
      });
  }

  getFaxes(direction: FaxMessage['direction']): Observable<FaxMessage[]> {
    if (this.faxLinks.get(direction)?.next === null) {
      return of([]);
    }
    const link = this.generateFaxLink(direction);
    return this.get<FaxMessageResponse>(`${link}`).pipe(
      catchError(() => {
        return of({ data: [], links: { next: null } });
      }),

      map((faxMessages) => {
        return faxMessages.data.map((faxMessage) => {
          this.faxLinks.set(direction, faxMessages.links);
          const msg = { ...faxMessage, direction: direction };
          this.faxMessageMap.set(msg.id, msg);
          return msg;
        });
      })
    );
  }

  private generateFaxLink(direction: FaxMessage['direction']) {
    return this.faxLinks.has(direction) ? `${direction}${this.faxLinks.get(direction)?.next}` : `${direction}`;
  }

  getLocalNumbers(): Observable<ListFaxNumbersResponse> {
    return this.get<ListFaxNumbersResponse>(`numberlist`);
  }

  override getHttpData(): Observable<FaxMessage[]> {
    this.dataLoadStateSubject.next('loading');
    return forkJoin([this.getFaxes('inbound'), this.getFaxes('outbound'), this.getLocalNumbers()]).pipe(
      map(([inboundFaxes, outboundFaxes, localNumbers]) => {
        console.log(`fax inbound: got ${inboundFaxes.length} messages`);
        console.log(`fax outbound: got ${outboundFaxes.length} messages`);
        console.log(`fax localNumbers: ${localNumbers.data.length}`);
        this.localFaxNumbersSubject.next(localNumbers.data);
        this.setLocalNumberFromUrl();
        return [...outboundFaxes, ...inboundFaxes];
      }),
      tap(() => {
        this.dataLoadStateSubject.next('loaded');
      })
    );
  }

  private setLocalNumberFromUrl() {
    const tree = this.router.parseUrl(this.currentUrl);
    const urlSegments: UrlSegment[] | undefined = tree.root.children[PRIMARY_OUTLET]?.segments;
    if (!urlSegments) {
      return;
    }

    const faxSegmentIndex = urlSegments.findIndex((segment) => segment.path === 'fax');
    if (faxSegmentIndex !== -1 && faxSegmentIndex + 1 < urlSegments.length) {
      const numberInPath = urlSegments[faxSegmentIndex + 1];
      const localNumber = [this.allLocalNumber, ...(this.localFaxNumbersSubject.value || [])].find(
        (number) => number.number === numberInPath.path
      );
      console.log(`fax: setting local number with value ${localNumber?.number}`);
      this.selectedFaxLocalNumberSubject.next(localNumber || this.allLocalNumber);
    }
  }

  private processFaxMessage(message: FaxMessage, direction: 'inbound' | 'outbound') {
    const normalizedFaxMessage: FaxMessage = {
      ...message,
      direction: direction,
      unread: direction == 'inbound',
    };

    if (this.faxMessageMap.has(message.id)) {
      this.faxMessageMap.set(message.id, normalizedFaxMessage);
    } else {
      // move the new fax on the top of the direction list
      this.faxMessageMap = new Map([[message.id, normalizedFaxMessage], ...this.faxMessageMap]);
    }
    this.setData([...this.faxMessageMap.values()]);
  }

  loadFaxMessages(tabSelected: number) {
    const direction: FaxMessage['direction'] = tabSelected === 0 ? 'inbound' : 'outbound';
    this.getFaxes(direction).subscribe(() => {
      this.setData([...this.faxMessageMap.values()]);
    });
  }

  setUnreadMark(message: FaxMessage) {
    if (this.faxMessageMap.has(message.id)) {
      const normalizedFaxMessage: FaxMessage = {
        ...message,
        unread: false,
      };
      this.faxMessageMap.set(message.id, normalizedFaxMessage);
      this.setData([...this.faxMessageMap.values()]);
    }
  }

  getThumbnail(id: string) {
    return this.get<Blob>(`${id}/thumbnail`, {
      responseType: 'blob' as 'json',
    }).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  downloadPDF(id: string) {
    return this.get<Blob>(`${id}/pdf`, {
      responseType: 'blob' as 'json',
    }).pipe(
      tap((response) => {
        this.faxBlobPdfData.set(id, response);
        return response;
      })
    );
  }

  downloadConfirmation(id: string) {
    return this.get<Blob>(`${id}/confirmation`, {
      responseType: 'blob' as 'json',
    }).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  public deleteFax(id: string) {
    return this.post<FaxDeleteResponse>(`${id}/delete`, {}).pipe(
      map((response) => {
        if (response.success) {
          //remove deleted item from fax list
          this.removeItem(id);
          this.faxMessageMap.delete(id);
        } else {
          console.warn(response.message);
        }
        return response;
      })
    );
  }

  downloadFax(id) {
    const isLoaded = this.faxBlobPdfData.get(id);
    if (isLoaded) {
      saveAs(isLoaded, `fax-${id}.pdf`);
    } else {
      this.downloadPDF(id).subscribe((response) => {
        saveAs(response, `fax-${id}.pdf`);
      });
    }
  }

  downloadFaxConfirmation(id) {
    this.downloadConfirmation(id).subscribe((response) => {
      saveAs(response, `fax-confrmation${id}.pdf`);
    });
  }

  printFax(id) {
    const isLoaded = this.faxBlobPdfData.get(id);
    if (isLoaded) {
      const pdfFile = new Blob([isLoaded], { type: 'application/pdf' });
      window.open(URL.createObjectURL(pdfFile), '');
    } else {
      this.downloadPDF(id).subscribe((response) => {
        const pdfFile = new Blob([response], { type: 'application/pdf' });
        window.open(URL.createObjectURL(pdfFile), '');
      });
    }
  }

  async addFaxUrl(file: File): Promise<FaxUrlResponse> {
    try {
      const response = await firstValueFrom(
        this.httpClient.post<FaxUrlResponse>(`${environment.faxHubGateway}/{me}/upload/url`, {
          filename: file.name,
          type: 'fax',
          access: 'private',
        })
      );
      await firstValueFrom(this.httpClient.put(response.url, file));
      return response;
    } catch (error) {
      if (error.message) {
        this.snackbar.open(error.message, undefined, { panelClass: SnackBarType.ERROR });
      }
      throw new Error('failed to add fax url');
    }
  }

  sendFax(formData: FormData): Observable<object | null> {
    this.sendingSubject.next(true);
    return this.post<FormData>('send', formData).pipe(finalize(() => this.sendingSubject.next(false)));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected override updateField(id: string, field: string, state: string | number | boolean): void {
    // Do nothing. We don't want behavior from the base class.
    // TODO: Remove reliance on base class for this service
  }

  // ========== Helpers ==========
  public isAllLocalNumber(number: string | LocalFaxNumber | null | undefined) {
    if (!number) {
      return false;
    }

    return typeof number === 'string'
      ? number === this.allLocalNumber.number
      : number.number === this.allLocalNumber.number;
  }

  isAllDataLoaded(direction: FaxMessage['direction']) {
    return this.faxLinks.get(direction)?.next === null;
  }
}

class FaxDeleteResponse {
  success: boolean;
  message: string;
}
