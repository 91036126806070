import { Contact } from '@app/contacts/models/contact';

export enum CoverPageOptions {
  None = 'none',
  Compose = 'compose',
  File = 'file',
}

export enum SentFaxStatus {
  Answered = 'answered',
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
  Error = 'error',
}

export interface SendFaxData {
  coverPage: CoverPageOptions;
  coverPageText: string | undefined;
  coverPageUrl: FaxUrlResponse | undefined;
  urls: FaxUrlResponse[];
  callerId: string;
  destination: string;
}

export const ALLOWED_FAX_FILES: string[] = ['.html', '.pdf', '.doc', '.docx', '.jpg', '.png', '.tiff', '.txt'];

export interface FaxMessage {
  id: string;
  caller_id: string;
  destination: number;
  created_by?: string;
  created_at: string;
  pages: number;
  pdf?: string;
  thumbnail?: string;
  confirmation?: string;
  status: string;
  transcription?: string;
  direction: 'inbound' | 'outbound';
  alias?: string;
  unread: boolean | false;
  contact?: Contact;
  read?: boolean;
}

export interface FaxMessageResponse {
  data: FaxMessage[];
  links: FaxLinks;
}

export interface FaxLinks {
  first?: string;
  last?: string;
  prev?: string | undefined;
  next: string | null;
}

export const AllLocalFaxNumber: LocalFaxNumber = {
  number: 'all',
};

export interface ListFaxNumbersResponse {
  success: boolean;
  message: string;
  data: LocalFaxNumber[];
}

export interface LocalFaxNumber {
  number: string;
  callback_url?: string[];
  email_address?: string[];
  description?: string;
  shared?: boolean;
}

export interface FaxUrlResponse {
  filename: string;
  key: string;
  url: string;
}
